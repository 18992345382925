import { firestore } from './firebase-config';
import { id_firebase, ids_firebase, href_revenda } from './content-site';

export default function XMLIntegracoes() {
    // Função para escapar caracteres especiais no XML
    function escapeXml(unsafe) {
        return unsafe.replace(/[<>&'"]/g, function (c) {
            switch (c) {
                case '<': return '&lt;';
                case '>': return '&gt;';
                case '&': return '&amp;';
                case '\'': return '&apos;';
                case '"': return '&quot;';
            }
        });
    }

    // Função para gerar a string XML
    function listaXML(allVehicles) {
        let xmlDoc = document.implementation.createDocument("", "", null);
        let rss = xmlDoc.createElement("rss");
        rss.setAttribute("version", "2.0");
        rss.setAttribute("xmlns:g", "http://base.google.com/ns/1.0");

        let channel = xmlDoc.createElement("channel");

        let title = xmlDoc.createElement("title");
        title.textContent = "Catálogo de Veículos";
        channel.appendChild(title);

        let link = xmlDoc.createElement("link");
        link.textContent = "https://autoshoppingimigrantes.com.br/#/";
        channel.appendChild(link);

        let description = xmlDoc.createElement("description");
        description.textContent = "Catálogo de veículos para venda";
        channel.appendChild(description);

        allVehicles.forEach(item => {
            let itemElement = xmlDoc.createElement("item");

            let idLoja = xmlDoc.createElement("g:idStore");
            idLoja.textContent = escapeXml(item.href_revenda);
            itemElement.appendChild(idLoja);

            let placa = xmlDoc.createElement("g:plate");
            placa.textContent = escapeXml(item.veiplaca);
            itemElement.appendChild(placa);

            let description = xmlDoc.createElement("g:description");
            description.textContent = escapeXml(`Brand: ${item.vefipe_marca},
                                        Model: ${item.vefipe_name},
                                        Km: ${item.vequilometragem},
                                        Fabrication: ${item.veano_fabricacao},
                                        Model year: ${item.vefipe_ano_modelo},
                                        Fuel: ${item.vefipe_combustivel},
                                        Car gearbox: ${item.vecambio},
                                        Color: ${item.vecor},
                                        Bodywork: ${item.vecarroceria},
                                        Doors: ${item.veportas}`);

            itemElement.appendChild(description);

            let allowedTypes = ['carro', 'moto', 'caminhao', 'outros'];
            if (allowedTypes.includes(item.vetipoveiculo)) {
                let type = xmlDoc.createElement("g:type");
                type.textContent = escapeXml(item.vetipoveiculo);
                itemElement.appendChild(type);
            }

            let opcionais = [
                { descricao: "Ar Condicionado" },
                { descricao: "Direção Hidráulica" },
                { descricao: "Vidros Elétricos" },
                { descricao: "Travas Elétricas" },
                { descricao: "Alarme" },
                { descricao: "Air Bag" },
                { descricao: "ABS" },
                { descricao: "Rodas em liga leve" },
                { descricao: "4x4" },
                { descricao: "Bancos em Couro" },
                { descricao: "Central Multimídia" },
                { descricao: "Teto Solar" },
                { descricao: "Direção Elétrica" },
                { descricao: "Som" },
                { descricao: "Banco com regulagem altura" },
                { descricao: "Capota Marítima" },
                { descricao: "Computador de Bordo" },
                { descricao: "Controle aut. de velocidade" },
                { descricao: "Controle de Tração" },
                { descricao: "Protetor de Caçamba" },
                { descricao: "Retrovisor Fotocrômico" },
                { descricao: "Retrovisores Elétricos" },
                { descricao: "Sensor de Chuva" },
                { descricao: "Sensor de Estacionamento" },
                { descricao: "Volante com Regulagem Altura" },
                { descricao: "Xênon" }
            ];
            
            if (item.opcionais && item.opcionais.length > 0) {
                item.opcionais.forEach(opcional => {
                    let opcionalElement = xmlDoc.createElement("g:opcional");
                    opcionalElement.textContent = escapeXml(opcional.descricao);
                    itemElement.appendChild(opcionalElement);
                });
            }
        
            

            let fipeId = xmlDoc.createElement("g:fipeId");
            fipeId.textContent = ""
            itemElement.appendChild(fipeId)

            let armored = xmlDoc.createElement("g:armored");
            armored.textContent = item.veblindado === false ? "Não" : "Sim";
            itemElement.appendChild(armored)

            let model = xmlDoc.createElement("g:model");
            model.textContent = escapeXml(item.ve_modelo || "")
            itemElement.appendChild(model)

            let version = xmlDoc.createElement("g:version");
            version.textContent = escapeXml(item.ve_versao || "")
            itemElement.appendChild(version)

            let link = xmlDoc.createElement("g:link");
            link.textContent = escapeXml(`https://autoshoppingimigrantes.com.br/#/${item.href_revenda}/${item.id}`);
            itemElement.appendChild(link);

            let image_link = xmlDoc.createElement("g:image_link");
            image_link.textContent = escapeXml(item.imagens[0]?.fileUrl || "");
            itemElement.appendChild(image_link);

            let condition = xmlDoc.createElement("g:condition");
            condition.textContent = item.veveiculonovo === 1 ? 'new' : 'used';
            itemElement.appendChild(condition);

            let availability = xmlDoc.createElement("g:availability");
            availability.textContent = item.vevendido === 0 ? 'in stock' : 'out of stock';
            itemElement.appendChild(availability);

            let price = xmlDoc.createElement("g:price");
            price.textContent = `${item.vevalor} BRL`;
            itemElement.appendChild(price);

            let brand = xmlDoc.createElement("g:brand");
            brand.textContent = escapeXml(item.vefipe_marca || "");
            itemElement.appendChild(brand);

            channel.appendChild(itemElement);
        });

        rss.appendChild(channel);
        xmlDoc.appendChild(rss);

        let serializer = new XMLSerializer();
        let xmlString = `<?xml version="1.0" encoding="UTF-8"?>\n` + serializer.serializeToString(xmlDoc);
        return xmlString;
    }

    // Função para salvar o arquivo XML
    function saveFile(nomeArquivo, conteudo) {
        const blob = new Blob([conteudo], { type: "application/xml" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = nomeArquivo;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Coleta os dados do Firestore e gera o arquivo XML
    const allVehicles = [];
    let completedQueries = 0;

    ids_firebase.forEach((id, index) => {
        const revendaDocRef = firestore.collection("revenda").doc(id);

        revendaDocRef.collection("veiculos")
            .where("veremovido", "==", 0)
            .where("vevendido", "==", 0)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    var vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = href_revenda[index]; // Adiciona a propriedade href_revenda
                    if (vehicle["veexibir_site"] !== false) {
                        allVehicles.push(vehicle);
                    }
                });

                completedQueries++;

                if (completedQueries === ids_firebase.length) {
                    allVehicles.sort((a, b) => a.vevalor - b.vevalor);
                    const xmlString = listaXML(allVehicles);
                    saveFile("estoque.xml", xmlString);
                    localStorage.setItem('xmlDownloaded', true);
                }
            })
            .catch(error => {
                console.error("Error getting documents: ", error);
            });
    });
}
