import { firestore } from './firebase-config';
import { id_firebase, ids_firebase, href_revenda } from './content-site';

export default function XML() {
    // Função para escapar caracteres especiais no XML
    function escapeXml(unsafe) {
        return unsafe.replace(/[<>&'"]/g, function (c) {
            switch (c) {
                case '<': return '&lt;';
                case '>': return '&gt;';
                case '&': return '&amp;';
                case '\'': return '&apos;';
                case '"': return '&quot;';
            }
        });
    }

    // Função para gerar a string XML
    function listaXML(allVehicles) {
        let xmlDoc = document.implementation.createDocument("", "", null);
        let rss = xmlDoc.createElement("rss");
        rss.setAttribute("version", "2.0");
        rss.setAttribute("xmlns:g", "http://base.google.com/ns/1.0");

        let channel = xmlDoc.createElement("channel");

        let title = xmlDoc.createElement("title");
        title.textContent = "Catálogo de Veículos";
        channel.appendChild(title);

        let link = xmlDoc.createElement("link");
        link.textContent = "https://autoshoppingimigrantes.com.br/#/";
        channel.appendChild(link);

        let description = xmlDoc.createElement("description");
        description.textContent = "Catálogo de veículos para venda";
        channel.appendChild(description);

        allVehicles.forEach(item => {
            let itemElement = xmlDoc.createElement("item");

            let id = xmlDoc.createElement("g:id");
            id.textContent = escapeXml(item.id);
            itemElement.appendChild(id);

            let title = xmlDoc.createElement("g:title");
            title.textContent = escapeXml(item.vefipe_name);
            itemElement.appendChild(title);

            let description = xmlDoc.createElement("g:description");
            description.textContent = escapeXml(`Brand: ${item.vefipe_marca},
                                        Model: ${item.vefipe_name},
                                        Km: ${item.vequilometragem},
                                        Fabrication: ${item.veano_fabricacao},
                                        Model year: ${item.vefipe_ano_modelo},
                                        Fuel: ${item.vefipe_combustivel},
                                        Car gearbox: ${item.vecambio},
                                        Color: ${item.vecor},
                                        Doors: ${item.veportas}`);
            itemElement.appendChild(description);

            let link = xmlDoc.createElement("g:link");
            link.textContent = escapeXml(`https://autoshoppingimigrantes.com.br/#/${item.href_revenda}/${item.id}`);
            itemElement.appendChild(link);

            let image_link = xmlDoc.createElement("g:image_link");
            image_link.textContent = escapeXml(item.imagens[0].fileUrl);
            itemElement.appendChild(image_link);

            let condition = xmlDoc.createElement("g:condition");
            condition.textContent = item.veveiculonovo === 1 ? 'new' : 'used';
            itemElement.appendChild(condition);

            let availability = xmlDoc.createElement("g:availability");
            availability.textContent = item.vevendido === 0 ? 'in stock' : 'out of stock';
            itemElement.appendChild(availability);

            let price = xmlDoc.createElement("g:price");
            price.textContent = `${item.vevalor} BRL`;
            itemElement.appendChild(price);

            let brand = xmlDoc.createElement("g:brand");
            brand.textContent = escapeXml(item.vefipe_marca);
            itemElement.appendChild(brand);

            channel.appendChild(itemElement);
        });

        rss.appendChild(channel);
        xmlDoc.appendChild(rss);

        let serializer = new XMLSerializer();
        let xmlString = `<?xml version="1.0" encoding="UTF-8"?>\n` + serializer.serializeToString(xmlDoc);
        return xmlString;
    }

    // Função para salvar o arquivo XML
    function saveFile(nomeArquivo, conteudo) {
        const blob = new Blob([conteudo], { type: "application/xml" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = nomeArquivo;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Coleta os dados do Firestore e gera o arquivo XML
    const allVehicles = [];
    let completedQueries = 0;

    ids_firebase.forEach((id, index) => {
        const revendaDocRef = firestore.collection("revenda").doc(id);

        revendaDocRef.collection("veiculos")
            .where("veremovido", "==", 0)
            .where("vevendido", "==", 0)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    var vehicle = doc.data();
                    vehicle.id = doc.id;
                    vehicle.href_revenda = href_revenda[index]; // Adiciona a propriedade href_revenda
                    if (vehicle["veexibir_site"] !== false) {
                        allVehicles.push(vehicle);
                    }
                });

                completedQueries++;

                if (completedQueries === ids_firebase.length) {
                    allVehicles.sort((a, b) => a.vevalor - b.vevalor);
                    const xmlString = listaXML(allVehicles);
                    saveFile("estoque.xml", xmlString);
                    localStorage.setItem('xmlDownloaded', true);
                }
            })
            .catch(error => {
                console.error("Error getting documents: ", error);
            });
    });
}
