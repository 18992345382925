import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <div className="item" key={v.id}>
            <div className="car-item text-center">
                <div className="car-image">
                    {
                        v.imagens.length > 0 ?
                            v.imagens[0].fileThumb !== undefined ?
                                <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                :
                                <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                            : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                    }
                    <div className="car-overlay-banner">
                        <ul>
                            <li><Link to={"/veiculo/" + v.id}><i className="fa fa-link"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="car-list">
                    <ul className="list-inline">
                        <li><i className="fa fa-registered"></i>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</li>
                        <li><i className="fa fa-cog"></i>{formatCambio(v.vecambio)}</li>
                        <li><i className="fa fa-dashboard"></i>{v.vequilometragem} KM</li>
                    </ul>
                </div>
                <div className="car-content">
                    <span>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</span>
                    <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                    <div className="separator"></div>
                    <div className="price">
                        <span className="new-price">{formatMoney(v.vevalor)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return <OwlCarousel className='owl-theme' autoplay={true} loop={true} items={2} margin={30} nav>{listVeiculos}</OwlCarousel>

}

function MenuHamb(opcao) {
    const opcoes = document.querySelectorAll(".menu-hamb")

    opcoes.forEach((element) => {
        element.classList.remove("show")
        if (opcao === "ESTOQUES" || element.classList.contains(opcoes)) {
            element.classList.add("show")
        }
    })
}


function ImagensLoja(data) {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) =>
        <div key={key}><img className="img-responsive center-block" src={key} alt={"Banner " + data.revenda.penome_fantasia} /></div>
    );
    return <Slider {...settings}>{listImagens}</Slider>;

}

class Inicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <div
                    id="rev_slider_3_1_wrapper"
                    className="rev_slider_wrapper fullwidthbanner-container rev_slider_3_1_wrapper"
                    data-alias="car-dealer-05"
                    data-source="gallery"
                >

                    <div
                        id="rev_slider_3_1"
                        className="rev_slider fullwidthabanner"
                        style={{ display: "none" }}
                        data-version="5.3.0.2"
                    >
                        <ul>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img01.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img01.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img02.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img02.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img03.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img03.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img04.jpeg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img04.jpeg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            {/*<li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="images/testimonial/img05.png"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="images/testimonial/img05.png"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li> */}

                        </ul>
                        <div
                            className="tp-bannertimer tp-bottom"
                            style={{ visibility: "hidden !important" }}
                        ></div>
                    </div>
                </div>

                <div className='box-logopainel'>
                    <img className='logopainel' src='./images/logopainel.png'></img>
                </div>

                <div className='box-ofertas'>
                    <Link className='ofertas' to='/estoque'> Veja todas as ofertas </Link>
                </div>

                <div class="menu-bg" id="menu-bg"></div>

                <section className="financiamento-section">
                    <div class="MuiBox-root jss1182 jss1133">
                        <div class="jss1132"></div>
                        <div class="MuiBox-root jss1183 jss1130">
                            <h2 class="secondary-label jss1129">Conheça as lojas parceiras<br />
                                <strong></strong></h2>
                            <div className="MuiBox-root jss1184">
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://canever.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/canever.png" alt="Loja 1 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://gbsmotors.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/gbs.png" alt="Loja 2 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://maverick.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/maverick.png" alt="Loja 3 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://pissarelli.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/pissarelli.png" alt="Loja 4 logo" className="logo-image" />
                                    </div>
                                </a><a className="jss653 jss1139 jss1131" target="_blank" href="https://premiummultimarcas.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/premium.png" alt="Loja 5 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://topsmotors.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/tops.png" alt="Loja 6 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://afcustomcars.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/afcustoms.png" alt="Loja 7 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://atacaar.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/atacaar.png" alt="Loja 8 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://evolutionmotors.ofertasautomoveis.com.br">
                                    <div className="logo-container">
                                        <img src="/images/logos/evolutionmotors.png" alt="Loja 9 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://astromultimarcas.autoshoppingimigrantes.com.br/#/">
                                    <div className="logo-container">
                                        <img src="/images/logos/astro.jpeg" alt="Loja 9 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://lucasmultimarcas.autoshoppingimigrantes.com.br/#/">
                                    <div className="logo-container">
                                        <img src="/images/logos/lucas.jpeg" alt="Loja 9 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://spinermotors.autoshoppingimigrantes.com.br/#/">
                                    <div className="logo-container">
                                        <img src="/images/logos/spiner.png" alt="Loja 9 logo" className="logo-image" />
                                    </div>
                                </a>
                                <a className="jss653 jss1139 jss1131" target="_blank" href="https://autodmultimarcas.autoshoppingimigrantes.com.br/#/">
                                    <div className="logo-container">
                                        <img src="/images/logos/autod.png" alt="Loja 9 logo" className="logo-image" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="latest-blog gray-bg page-section-ptb" id='empresa'>
                    <h1>Conheça o Auto Shopping Imigrantes</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" >
                                <div className="section-title empresa" >
                                </div>
                            </div>
                        </div>
                        <div className="row empresaImg">
                            <div className="col-lg-4 col-md-12">
                                <div className="blog-2">
                                    <div className="blog-image">
                                        <img
                                            className="img-fluid"
                                            src="images/trimestrais/01.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="blog-2">
                                    <div className="blog-image">
                                        <img
                                            className="img-fluid"
                                            src="images/trimestrais/02.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <div className="blog-2">
                                    <div className="blog-image">
                                        <img
                                            className="img-fluid"
                                            src="images/trimestrais/03.jpg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* <section className="welcome-3 white-bg page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="feature-box-2 box-hover">
                                    <div className="icon" style={{display: "flex", justifyContent: "center"}}>
                                        <i className="glyph-icon flaticon-car"></i>
                                    </div>
                                    <div className="content">
                                        <h5 style={{textAlign:"center"}}>Todas as marcas</h5>
                                        <p>
                                            Nós oferecemos uma ampla variedade de marcas de carros de alta qualidade, para atender a todas as necessidades e estilos de direção.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box-2 box-hover">
                                    <div className="icon" style={{display: "flex", justifyContent: "center"}}>
                                        <i className="glyph-icon flaticon-interface-1"></i>
                                    </div>
                                    <div className="content">
                                        <h5 style={{textAlign:"center"}}>Atendimento</h5>
                                        <p>Nós priorizamos sempre o bem-estar e satisfação de nossos clientes, oferecendo um atendimento excepcional e personalizado. Confira nossas redes sociais e nosso atendimento on-line!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box-2 box-hover">
                                    <div className="icon" style={{display: "flex", justifyContent: "center"}}>
                                        <i className="glyph-icon flaticon-key"></i>
                                    </div>
                                    <div className="content">
                                        <h5 style={{textAlign:"center"}}>Venda seu veículo</h5>
                                        <p>
                                            Venda seu carro com confiança, sabendo que estamos oferecendo um preço justo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="feature-box-2 box-hover">
                                    <div className="icon" style={{display: "flex", justifyContent: "center"}}>
                                        <i className="glyph-icon flaticon-wallet"></i>
                                    </div>
                                    <div className="content">
                                        <h5 style={{textAlign:"center"}}>Financiamento</h5>
                                        <p>
                                            Nós ajudamos você a realizar o sonho de possuir um carro novo, com opções flexíveis e acessíveis de financiamento para atender a todas as necessidades, buscamos sempre as menores taxas de juros para nossos clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                    <section class="company-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="point">
                                        <i class="fas fa-building"></i>
                                        <div>
                                            <h3>Modernas Instalações</h3>
                                            <p>Nossa loja foi projetada para proporcionar a melhor experiência aos nossos clientes, com instalações modernas e aconchegantes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="point">
                                        <i class="fas fa-car"></i>
                                        <div>
                                            <h3>Exposição de Veículos</h3>
                                            <p>A exposição dos nossos veículos é feita com segurança e conforto, para que você possa escolher o seu próximo carro com tranquilidade.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="point">
                                        <i class="fas fa-headset"></i>
                                        <div>
                                            <h3>Atendimento Personalizado</h3>
                                            <p>Nosso atendimento é personalizado e exclusivo, para que você se sinta à vontade e escolha o seu próximo carro com confiança.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <h1>Espaço gourmet exclusivo com o Shêvi Restaurant <br/> dentro do Auto Shopping Imigrantes.</h1>
                        <div className='box-ofertas'>
                            <a className='ofertas' href='https://shevirestaurant.autoshoppingimigrantes.com.br/#/'> Espaço gourmet </a>
                        </div>
                    </section>
                </section>

                <hr className="gray"></hr>

                <Rodape revenda={this.state.revenda} />
            </div >
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Inicio